import { Core, WebViewerInstance } from '@pdftron/webviewer';

import { CustomDataKey } from '@/constants/pdfViewer/customDataKey.ts';
import { ReviewIdentifiedBlockConfidence } from '@/firestore/api/reviewIdentifiedBlock.ts';
import { identifiedBlockColorByConfidence } from '@/pages/ReviewPage';
import { addCommentForAnnotation } from '@/utils/pdfViewer/addAnnotationWithComment.ts';
import { CustomToolNames } from '@/widgets/PdfViewer2/PdfViewer2.tsx';
import {
    AnnotationConfig,
    AnnotationVariant,
    getAnnotationConfigByVariant,
} from '@/widgets/PdfViewer2/PdfViewer2.types.ts';
import { stylesByCutsomTool } from '@/widgets/PdfViewer2/useToolsListener.ts';

export const createAnnotation = async ({
    pdfInstance,
    type,
    pageIndex,
    coordinates,
    toolName,
    annotationManager,
    customData,
    relatedSnapshotId,
    showInNotesPanel = true,
    readOnly = false,
    reply,
    annotationVariant,
    padding = 0,
}: {
    pdfInstance: WebViewerInstance,
    type: ReviewIdentifiedBlockConfidence,
    pageIndex: number,
    coordinates: [number, number, number, number],
    toolName: CustomToolNames,
    /**
     * Can be used instead of toolName for styling (when there is not manual toold for that
     */
    annotationVariant?: AnnotationVariant,
    annotationManager: Core.AnnotationManager
    customData?: Record<Partial<CustomDataKey>, string>
    relatedSnapshotId?: string
    showInNotesPanel?: boolean
    readOnly?: boolean
    reply?: string
    padding?: number
}): Promise<Core.Annotations.Annotation | void> => {
    try {
        const allannotations = annotationManager.getAnnotationsList()
        // We need to create manu links for the same block
        const snapshotId = relatedSnapshotId
        
        const isThisSnapshotAlreadyAnnotated = snapshotId?.length ? allannotations.some((annotation) => {
            const customDataSnapShotId = annotation.getCustomData('relatedSnapshotId')
            return customDataSnapShotId === snapshotId
        }) : false;

        // FIXME: Make ib based on relatedSnapshotId + annotationIndex (less custom staff)
        const relatedLinkSnapshotId = customData?.relatedLinkSnapshotId
        const linkIndex = customData?.linkIndex

        // Different logic for links
        const previouslyCreatedLink = (relatedLinkSnapshotId?.length && linkIndex?.length) ? allannotations.find((annotation) => {
            const customDataSnapShotId = annotation.getCustomData('relatedLinkSnapshotId')
            const customDataLinkIndex = annotation.getCustomData('linkIndex')
            return customDataSnapShotId === relatedLinkSnapshotId && customDataLinkIndex === linkIndex
        }) : false

        if(isThisSnapshotAlreadyAnnotated) {
            // console.info('Annotation already created for block', snapshotId)
            return
        }
        
        const newAnnotationObj = new pdfInstance.Core.Annotations.RectangleAnnotation();

        const colorByConf = identifiedBlockColorByConfidence(pdfInstance)[type]

        const toolStyles = stylesByCutsomTool(pdfInstance)[toolName]
        const annotationVariantStyles: AnnotationConfig = annotationVariant ? getAnnotationConfigByVariant(pdfInstance)[annotationVariant] : {}
        
        const borderStyle = annotationVariantStyles?.BorderStyle
        const strokeThinckness = annotationVariantStyles?.StrokeThickness || toolStyles.StrokeThickness
        const opacity = annotationVariantStyles?.Opacity || toolStyles.Opacity

        newAnnotationObj.PageNumber = pageIndex + 1;
        newAnnotationObj.X = coordinates[0] - padding;
        newAnnotationObj.Y = coordinates[1] - padding;
        newAnnotationObj.Width = coordinates[2] + padding * 2;
        newAnnotationObj.Height = coordinates[3] + padding * 2;
        newAnnotationObj.StrokeColor = annotationVariantStyles?.StrokeColor || colorByConf;
        newAnnotationObj.FillColor = colorByConf;
        newAnnotationObj.Opacity = opacity;
        newAnnotationObj.StrokeThickness = strokeThinckness;
        newAnnotationObj.IsHoverable = true;
        if(toolName) {
            newAnnotationObj.ToolName = toolName;
        }
        newAnnotationObj.NoDelete = false;
        newAnnotationObj.NoMove = true;
        newAnnotationObj.NoResize = true;
        newAnnotationObj.Author = toolName
        newAnnotationObj.Listable = showInNotesPanel
        newAnnotationObj.ReadOnly = readOnly

        ;(borderStyle === 'dash') && (newAnnotationObj.setBorderStyle('dash'))

        const allCustomData = customData ? {
            ...customData,
        } : {}
        
        if(relatedSnapshotId) {
            allCustomData['relatedSnapshotId'] = relatedSnapshotId
        }
        
        allCustomData && Object.entries(allCustomData).forEach(([key, value]) => {
            newAnnotationObj.setCustomData(key, value);
        })

        annotationManager.addAnnotation(newAnnotationObj);
        annotationManager.redrawAnnotation(newAnnotationObj);
        
        // On first load event won't be created automatically if PDF still loading (annotations will be available with annotationsLIst anyway)
        annotationManager.trigger('annotationChanged', [[newAnnotationObj], 'add', { imported: false, force: true }]);
        
        if(previouslyCreatedLink) {
            // Delete previous link annotation before crating a new ony
            // Hack for fix the problem with invisible annotations diring first loading
            annotationManager.deleteAnnotation(previouslyCreatedLink)
        }
                
        if(reply) {
            await addCommentForAnnotation({
                annotation: newAnnotationObj,
                commentText: reply,
                annotationManager,
                instance: pdfInstance,
                silentAdd: true,
            })
        }
        return newAnnotationObj
    } catch (e) {
        debugger
        console.error('Error creating annotation', type, e)
    }
}
