import { WebViewerInstance } from '@pdftron/webviewer';

export interface PdfViewer2Props {
    fileUrl: string
}

export enum AnnotationVariant {
    moneyValue = 'moneyValue',
}

export interface AnnotationConfig {
    StrokeColor: unknown
    Opacity: number
    StrokeThickness: number
    BorderStyle: 'dash'
}

export const getAnnotationConfigByVariant = (pdfInstance: WebViewerInstance): Record<AnnotationVariant, AnnotationConfig> => ({
    [AnnotationVariant.moneyValue]: {
        StrokeColor: new pdfInstance.Core.Annotations.Color(22, 117, 147),
        Opacity: 0.4,
        StrokeThickness: 0.8,
        BorderStyle: 'dash',
    },
})
